import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import axios from '../config/axios.config'
import { SurveyFormValues } from './survey.types'
import { AxiosResponse } from 'axios'

const updateRequest = (data: SurveyFormValues) => {
  const { photos, ...formData } = data

  const bodyFormData = new FormData()

  for (let [key, value] of Object.entries(formData)) {
    bodyFormData.append(key, value)
  }

  for (const image of photos) {
    bodyFormData.append('files', image)
  }

  return axios.post(`/survey/`, bodyFormData)
}

export const useSurvey = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: () => void
}): {
  isLoading: boolean
  submit: (data: SurveyFormValues) => void
} => {
  const client = useQueryClient()
  const { isLoading, mutate } = useMutation({
    mutationFn: (data: SurveyFormValues) => updateRequest(data),
    mutationKey: ['faults'],
    onSuccess: () => {
      onSuccess?.()
      return client.invalidateQueries(['faults'])
    },
    onError,
  })

  return {
    submit: mutate,
    isLoading,
  }
}
