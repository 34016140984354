import { useMutation, useQueryClient } from 'react-query'
import axios from '../config/axios.config'

export interface IUpdateFlow {
  flow: number
  decision: string
  flowData?: any
}

const updateRequest = (id: number, flow: number, decision: string, flowData?: any) => {
  const data = { decision: decision, ...flowData }

  return axios.patch(`/faults/${id}/`, { flow,  data }, { headers: { 'Content-Type': 'application/json' }})
}

export const useUpdateFlow = (
  id: number,
): { isLoading: boolean; updateFlow: (ard: IUpdateFlow) => void } => {
  const client = useQueryClient()
  const { isLoading, mutate } = useMutation({
    mutationFn: ({ flow, decision, flowData }: IUpdateFlow) =>
      updateRequest(id, flow, decision, flowData),
    mutationKey: ['faults'],
    onSuccess: () => {
      client.invalidateQueries(['faults'])
    },
  })

  return {
    updateFlow: mutate,
    isLoading,
  }
}
