import { useParams } from 'react-router-dom'

import { Grid, Image, Table, LoadingOverlay } from '@mantine/core'
import { formatDateToUI } from '../../utils/date.utils'
import { useFaultDetails } from '../../api/useFaultDetails'
import styles from './FaultDetails.module.scss'
import { StatusBadge } from '../StatusDropdown'
import { ImageModal, useImageModal } from '../ImageModal'
import { useEffect } from 'react'
import { FaultTimeline } from '../FaultTimeline/FaultTimeline'

export function FaultDetails() {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useFaultDetails(id || '')
  const { openModal, setFiles } = useImageModal()

  useEffect(() => {
    setFiles(data?.photos || [])
  }, [data])

  if (!data) {
    return <LoadingOverlay visible={isLoading} />
  }

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <Table withColumnBorders>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Data zgłoszenia</Table.Td>
                <Table.Td>{formatDateToUI(data.created)}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Status</Table.Td>
                <Table.Td>
                  <StatusBadge status={data.status} />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Typ</Table.Td>
                <Table.Td>{data.fault_type}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Budynek</Table.Td>
                <Table.Td>{data.fault_localization_part1}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Adres</Table.Td>
                <Table.Td>{data.fault_localization_part2}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={5} offset={3}>
          <FaultTimeline />
        </Grid.Col>
        <Grid.Col span={7}>
          <Grid className={styles.ImagesContainer}>
            {data.photos.map((el, i) => (
              <Grid.Col
                span={3}
                key={el.id}
                className={styles.ImageWrapper}
                onClick={() => openModal(i)}
              >
                <Image src={el.file} className={styles.Image} />
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
      <ImageModal />
    </>
  )
}
