import { useUpdateFlow } from '../../api/useUpdateFlow'
import { Button, CloseButton, Radio, Text, Textarea } from '@mantine/core'
import { FaultStatus } from '../../api/fault.types'
import { useState } from 'react'

enum FormType {
  reject = 'reject',
  accept = 'accept',
}

export function ReportedForm({
  id,
  status,
}: {
  id: number
  status?: FaultStatus
}) {
  const { updateFlow } = useUpdateFlow(id)
  const [showForm, setShowForm] = useState<FormType | null>(null)
  const [rejectReason, setRejectReason] = useState<string>('')
  const [selectedType, setSelectedType] = useState<string>('')

  return (
    status === FaultStatus.reported && (
      <>
        {!showForm && (
          <>
            <Text size='xs' mt={4}>
              Czy chcesz zaakceptować?
            </Text>
            <Button
              mt='md'
              mr='md'
              variant='filled'
              color='teal'
              onClick={() => setShowForm(FormType.accept)}
            >
              Zaaakceptuj
            </Button>
            <Button
              mt='md'
              mr='md'
              variant='filled'
              color='red'
              onClick={() => setShowForm(FormType.reject)}
            >
              Odrzuć
            </Button>
          </>
        )}
        {showForm === FormType.accept && (
          <>
            <Radio.Group
              value={selectedType}
              onChange={setSelectedType}
              name='selectedType'
              label='Wybierz rodzaj usterki'
              withAsterisk
            >
              <Radio value='electric' label='Elektryczna' />
              <Radio value='sanitary' label='Sanitarna' />
              <Radio value='construction' label='Budowlana' />
            </Radio.Group>
            <Button
              mt='md'
              mr='md'
              variant='filled'
              color='teal'
              onClick={() =>
                selectedType &&
                updateFlow({
                  flow: 3,
                  decision: 'accepted',
                  flowData: { fault_type: selectedType },
                })
              }
            >
              Wyślij
            </Button>
            <CloseButton onClick={() => setShowForm(null)} />
          </>
        )}
        {showForm === FormType.reject && (
          <>
            <Textarea
              label='Podaj powód odrzucenia'
              value={rejectReason}
              onChange={e => setRejectReason(e.currentTarget.value)}
            />
            <Button
              mt='md'
              mr='md'
              variant='filled'
              color='teal'
              onClick={() =>
                rejectReason &&
                updateFlow({
                  flow: 3,
                  decision: 'rejected',
                  flowData: { rejection_reason: rejectReason },
                })
              }
            >
              Wyślij
            </Button>
            <CloseButton onClick={() => setShowForm(null)} />
          </>
        )}
      </>
    )
  )
}
