import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import axios from '../config/axios.config'
import { Fault } from './fault.types'
import { useAuthHeader } from 'react-auth-kit'

const getFaults = async (): Promise<AxiosResponse<Fault[]>> => {
  return axios.get('/faults/')
}

export const useFaults = (): {
  data: Fault[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
} => {
  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ['faults'],
    queryFn: () => getFaults(),
    keepPreviousData: true,
  })

  return {
    data: data?.data ? data.data : [],
    isLoading,
    isError,
    isFetching,
  }
}
