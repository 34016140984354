import { FaultTable } from '../FaultTable'
import { useIsAuthenticated } from 'react-auth-kit'

export function DashboardPage() {
  const isAuthenticated = useIsAuthenticated()

  return (
    <>
      <h1>Dashboard</h1>
      <FaultTable />
    </>
  )
}
