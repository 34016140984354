import { LoadingOverlay, Timeline } from '@mantine/core'
import { useParams } from 'react-router-dom'

import { useFaultDetails } from '../../api/useFaultDetails'
import { useUpdateFlow } from '../../api/useUpdateFlow'
import { useChangeLog } from '../../api/useChangeLog'
import { InitiallyReportedForm } from './InitiallyReportedForm'
import { PreAcceptedForm } from './PreAcceptedForm'
import { ReportedForm } from './ReportedForm'
import { AcceptedForm } from './AcceptedForm'
import { FinishForm } from './FinishForm'
import { EstimateForm } from './EstimateForm'
import { FaultStatus } from '../../api/fault.types'

const calculateCurrentStep = (status?: FaultStatus): number => {
  switch (status) {
    case FaultStatus.initially_reported:
      return 1
    case FaultStatus['pre-accepted']:
      return 2
    case FaultStatus.reported:
      return 3
    case FaultStatus.accepted:
    case FaultStatus.costing_rejected:
      return 4
    case FaultStatus['for-approval']:
      return 5
    case FaultStatus.in_progress:
      return 6
    case FaultStatus.completed:
      return 7
    default:
      return 0
  }
}

export function FaultTimeline() {
  const { id } = useParams<{ id: string }>()
  const { data } = useFaultDetails(id || '')
  const { isLoading } = useUpdateFlow(Number(id))

  // const { data: changeLogData } = useChangeLog(id || '')

  const currentStatusNumber: number = calculateCurrentStep(data?.status)

  return (
    <>
      <Timeline active={currentStatusNumber}>
        <Timeline.Item title='Zgłoszenie'>{data?.created}</Timeline.Item>
        <Timeline.Item title='Wstepna Akceptacja'>
          <InitiallyReportedForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Zgłoszona usterka'>
          <PreAcceptedForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Akceptacja inspektora'>
          <ReportedForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Kosztorys'>
          <EstimateForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Akceptacja wyceny'>
          <AcceptedForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Naprawa w trakcie'>
          <FinishForm id={Number(id)} status={data?.status} />
        </Timeline.Item>
        <Timeline.Item title='Zakończone' />
      </Timeline>
      <LoadingOverlay visible={isLoading} />
    </>
  )
}
