type Reporter = {
  id: number
  created: string
  modified: string
  name: string
  phone: string
  email: string
}

export type Photo = {
  id: number
  created: string
  modified: string
  file: string
}

export enum FaultStatus {
  initially_reported = 'initially_reported',
  'pre-accepted' = 'pre-accepted',
  rejected = 'rejected',
  reported = 'reported',
  accepted = 'accepted',
  'for-approval' = 'for-approval',
  costing_rejected = 'costing_rejected',
  in_progress = 'in_progress',
  completed = 'completed',
}

export type Fault = {
  id: number
  created: string
  modified: string
  fault_type: string //could be enum when contract will be settle
  fault_localization_part1: string
  fault_localization_part2: string
  reporter: Reporter
  photos: Photo[]
  status: FaultStatus
}
