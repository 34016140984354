import { createContext, useContext, useEffect, useState } from 'react'
import { Photo } from '../../api/fault.types'

interface IImageModalContext {
  currentFileIndex: number
  closeModal: () => void
  openModal: (fileIndex: number) => void
  setFiles: (files: Photo[]) => void
  currentFile?: Photo
  nextFile: () => void
  prevFile: () => void
}

const ImageModalContext = createContext<IImageModalContext>({
  currentFileIndex: -1,
  closeModal: () => {},
  openModal: (fileIndex: number) => {},
  setFiles: (files: Photo[]) => {},
  nextFile: () => {},
  prevFile: () => {},
})

export const ImageModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [currentFileIndex, setcurrentFileIndex] = useState<number>(-1)
  const [files, setFiles] = useState<Photo[]>([])

  return (
    <ImageModalContext.Provider
      value={{
        currentFileIndex,
        openModal: fileIndex => setcurrentFileIndex(fileIndex),
        closeModal: () => setcurrentFileIndex(-1),
        setFiles: (files: Photo[]) => setFiles(files),
        currentFile: files[currentFileIndex],
        nextFile: () => {
          if (currentFileIndex < files.length - 1)
            setcurrentFileIndex(currentFileIndex + 1)
          else setcurrentFileIndex(0)
        },
        prevFile: () => {
          if (currentFileIndex > 0) setcurrentFileIndex(currentFileIndex - 1)
          else setcurrentFileIndex(files.length - 1)
        },
      }}
    >
      {children}
    </ImageModalContext.Provider>
  )
}

export function useImageModal() {
  const context = useContext(ImageModalContext)

  if (context === undefined) {
    throw new Error('ImageModal must be used within a ImageModalContext')
  }

  const {
    openModal,
    closeModal,
    currentFileIndex,
    currentFile,
    setFiles,
    prevFile,
    nextFile,
  } = context

  return {
    openModal: (a: number) => {
      openModal(a)
    },
    closeModal,
    isOpen: currentFileIndex > -1,
    currentFile,
    setFiles,
    nextFile,
    prevFile,
  }
}
