import axiosDefault from 'axios'
import { AUTH_NAME } from '../utils/auth.utils'

const baseApiUrl = process.env.REACT_APP_PUBLIC_BACKEND_URL as string

const NO_AUTH_URLS: string[] = ['/login/', '/survey/']

const axios = axiosDefault.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

function getCookie(name: string) {
  let cookie: Record<string, string> = {}
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

axios.interceptors.request.use(config => {
  if (NO_AUTH_URLS.indexOf(config.url || '') > -1) return config

  config.headers['Authorization'] = `Token ${getCookie(AUTH_NAME)}`

  return config
})

export default axios
