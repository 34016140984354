import { useForm } from '@mantine/form'
import { Button, Container, Input, LoadingOverlay, PasswordInput, Stack } from '@mantine/core'
import { useAuthHeader, useSignIn } from 'react-auth-kit'
import axios from '../../config/axios.config'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { TextInput, Text, Paper, Group, Checkbox, Anchor } from '@mantine/core'
import { useToggle, upperFirst } from '@mantine/hooks'
import { useState } from 'react'

export function LoginPage() {
  const navigate = useNavigate()
  const [type, toggle] = useToggle(['zaloguj', 'register'])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const signIn = useSignIn()

  const form = useForm({
    initialValues: {
      login: '',
      password: '',
      terms: true,
      name: '',
    },
  })

  return (
    <Container size='xs' px='xs'>
      <Paper radius='md' p='xl' withBorder>
        <Text size='lg' fw={500}>
          Witamy ponownie, {type} się.
        </Text>{' '}
        <form
          onSubmit={form.onSubmit(values => {
            setIsLoading(true)
            axios
              .post('/login/', {
                username: values.login,
                password: values.password,
              })
              .then(res => {
                if (
                  signIn({
                    token: res.data.token,
                    expiresIn: dayjs(res.data.expiry).diff(dayjs(), 'minute'),
                    tokenType: 'Token',
                    authState: {},
                  })
                ) {
                  navigate('/dashboard')
                } else {
                  setIsLoading(false)
                  form.setFieldError('login', 'error')
                  form.setFieldError('password', 'error')
                }
              })
              .catch(() => {
                setIsLoading(false)
                form.setFieldError('login', 'error')
                form.setFieldError('password', 'error')
              })
          })}
        >
          <Stack>
            {/* {type === "register" && (
              <TextInput
                label="Name"
                placeholder="Your name"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                radius="md"
              />
            )} */}

            <TextInput
              required
              label='Login'
              placeholder='login'
              { ...form.getInputProps('login') }
              radius='md'
            />

            <PasswordInput
              required
              label='Password'
              placeholder='Your password'
              { ...form.getInputProps('password') }
              radius='md'
            />

            {type === 'register' && (
              <Checkbox
                label='I accept terms and conditions'
                checked={form.values.terms}
                onChange={event =>
                  form.setFieldValue('terms', event.currentTarget.checked)
                }
              />
            )}
          </Stack>

          <Group justify='space-between' mt='xl'>
            {/* <Anchor
              component="button"
              type="button"
              c="dimmed"
              onClick={() => toggle()}
              size="xs"
            >
              {type === "register"
                ? "Already have an account? Login"
                : "Don't have an account? Register"}
            </Anchor> */}
            <Button type='submit' radius='xl'>
              {upperFirst(type)}
            </Button>
          </Group>
        </form>
      </Paper>
      <LoadingOverlay visible={isLoading} />
    </Container>
  )
}
