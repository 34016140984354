import { MantineProvider, createTheme } from '@mantine/core'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryProvider } from './config/reactQuery.config'
import { AppShellProvider } from './components/AppShell'
import { Survey } from './components/Survey'
import { LoginPage } from './components/LoginPage'
import { DashboardPage } from './components/DashboardPage/DashboardPage'
import { FaultDetails } from './components/FaultDetails/FaultDetails'
import { AuthProvider, RequireAuth } from 'react-auth-kit'
import { AUTH_NAME } from './utils/auth.utils'
import { ImageModalProvider } from './components/ImageModal'

const theme = createTheme({})

function App() {
  return (
    <AuthProvider
      authType={'cookie'}
      authName={AUTH_NAME}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === 'https:'}
    >
      <MantineProvider theme={theme}>
        <QueryProvider>
          <BrowserRouter basename={'/'}>
            <AppShellProvider>
              <Routes>
                <Route path={'/'} element={<div>Main</div>} />
                <Route path={'/survey'} element={<Survey />} />
                <Route
                  path={'/dashboard'}
                  element={
                    <RequireAuth loginPath={'/login'}>
                      <DashboardPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path={'/dashboard/details/:id'}
                  element={
                    <RequireAuth loginPath={'/login'}>
                      <ImageModalProvider>
                        <FaultDetails />
                      </ImageModalProvider>
                    </RequireAuth>
                  }
                />
                <Route path={'/login'} element={<LoginPage />} />
              </Routes>
            </AppShellProvider>
          </BrowserRouter>
        </QueryProvider>
      </MantineProvider>
    </AuthProvider>
  )
}

export default App
