import { Flex, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import { useIsAuthenticated, useSignOut } from 'react-auth-kit'
import classes from './AppShellProvider.module.scss'

export function Navigation() {
  const isAuthenticated = useIsAuthenticated()
  const signOut = useSignOut()

  return (
    <Flex
      mih={50}
      gap='md'
      justify='flex-end'
      align='center'
      direction='row'
      wrap='wrap'
      className={classes.header}
    >
      {!isAuthenticated() && (
        <Link to='/login'>
          <Button variant='subtle' color='white'>
            Logowanie
          </Button>
        </Link>
      )}
      {isAuthenticated() && (
        <>
          <Link to='/'>
            <Button variant='subtle' color='white'>
              Home
            </Button>
          </Link>
          <Link to='/survey'>
            <Button variant='subtle' color='white'>
              Zgłoś usterkę
            </Button>
          </Link>
          <Link to='/dashboard'>
            <Button variant='subtle' color='white'>
              Dashboard
            </Button>
          </Link>
          <Link to='/' onClick={signOut}>
            <Button variant='subtle' color='white'>
              Wyloguj
            </Button>
          </Link>
        </>
      )}
    </Flex>
  )
}

export default Navigation
