import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import axios from '../config/axios.config'
import { Fault } from './fault.types'

const getFaults = async (id: string): Promise<AxiosResponse<Fault>> => {
  return axios.get(`/faults/${id}/`)
}

export const useFaultDetails = (
  id: string,
): {
  data: Fault | null
  isLoading: boolean
  isError: boolean
  isFetching: boolean
} => {
  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ['faults', 'faultDetails', id],
    queryFn: () => getFaults(id),
    keepPreviousData: true,
  })

  return {
    data: data?.data ? data.data : null,
    isLoading,
    isError,
    isFetching,
  }
}
