import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  FileInput,
  LoadingOverlay,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { notifications } from '@mantine/notifications'
import axios from '../../config/axios.config'
import { useEffect, useState } from 'react'
import styles from './Survey.module.scss'
import { SurveyFormValues } from '../../api/survey.types'
import { useSurvey } from '../../api/useSurvey'

export function Survey() {
  const form = useForm<SurveyFormValues>({
    initialValues: {
      fault_type: '',
      fault_description: '',
      fault_localization_part1: '',
      fault_localization_part2: '',
      email: '',
      phone: '',
      name: '',
      photos: [],
    },
  })

  const [imageHelperText, setImageHelperText] = useState<string>()

  useEffect(() => {
    const photos = form.values.photos

    if (photos.length == 0) {
      setImageHelperText('Nie załączono plikow.')
    } else if (photos.length == 1) {
      setImageHelperText('Załączono 1 plik.')
    } else setImageHelperText('Załączono (' + photos.length + ') pliki.')
  }, [form.values.photos])

  const onSurveySuccess = () => {
    notifications.show({
      id: 'load-data',
      color: 'blue',
      title: 'Success',
      message: '',
      autoClose: 5000,
    })
    form.reset()
    setImageHelperText('')
  }

  const onSurveyError = () => {
    notifications.show({
      id: 'load-data',
      color: 'red',
      title: 'Wrong data',
      message: '',
      autoClose: 5000,
    })
  }

  const { submit, isLoading: isPending } = useSurvey({
    onSuccess: onSurveySuccess,
    onError: onSurveyError,
  })

  const handleSubmit = async (values: SurveyFormValues) => {
    submit(values)
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <div className={styles.wrapper}>
        <SimpleGrid cols={2} spacing={50}>
          <div>
            <Title className={styles.title}>Zgłoś usterkę</Title>
            <Text className={styles.description} mt='sm' mb={30}>
              Wypełniony formularz zostanie przekazany do administratora
              budynku.
            </Text>
          </div>

          <div className={styles.form}>
            <Textarea
              label='Opis usterki'
              required
              autosize
              minRows={2}
              classNames={{
                input: styles.input,
                label: styles.inputLabel,
              }}
              {...form.getInputProps('fault_description')}
            />
            <Textarea
              label='Adres'
              description='Szczegółowy adres usterki'
              minRows={2}
              mt='md'
              classNames={{
                input: styles.input,
                label: styles.inputLabel,
              }}
              {...form.getInputProps('fault_localization_part2')}
            />
            <FileInput
              multiple
              label='Załączniki'
              placeholder='Zdjęcia'
              mt='md'
              accept='image/jpeg, image/png, image/jpg'
              {...form.getInputProps('photos')}
            />
            {imageHelperText}
            <TextInput
              label='Imię i nazwisko'
              placeholder='Jan Kowalki'
              mt='md'
              classNames={{
                input: styles.input,
                label: styles.inputLabel,
              }}
              {...form.getInputProps('name')}
            />
            <TextInput
              mt='lg'
              label='Email'
              placeholder='twoj@email.com'
              classNames={{
                input: styles.input,
                label: styles.inputLabel,
              }}
              {...form.getInputProps('email')}
            />
            <TextInput
              mt='lg'
              label='Numer telefonu'
              classNames={{
                input: styles.input,
                label: styles.inputLabel,
              }}
              {...form.getInputProps('phone')}
            />
            <Group align='right' mt='md'>
              <Button
                className={styles.control}
                type='submit'
                disabled={isPending}
                variant={'outline'}
              >
                Wyślij
              </Button>
            </Group>
            <LoadingOverlay visible={isPending} zIndex={1000} />
          </div>
        </SimpleGrid>
      </div>
    </form>
  )
}
