import Navigation from './Navigation'
import { AppShell, Container } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import styles from './AppShellProvider.module.scss'

export function AppShellProvider({ children }: { children: React.ReactNode }) {
  return (
    <AppShell header={{ height: 60 }}>
      <AppShell.Header>
        <Navigation />
      </AppShell.Header>
      <AppShell.Main className={styles.Background}>
        <Container size={'lg'} bg='white' className={styles.Container}>
          {children}
          <Notifications position={'bottom-center'} />
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}

export default AppShellProvider
