import { FaultStatus } from '../../api/fault.types'
import { useStatusUpdate } from '../../api/useStatusUpdate'
import { Combobox, UnstyledButton, useCombobox } from '@mantine/core'
import { StatusBadge } from './StatusBadge'

const SelectOptions: FaultStatus[] = [
  FaultStatus.reported,
  FaultStatus.accepted,
  FaultStatus.in_progress,
  FaultStatus.completed,
  FaultStatus.rejected,
  FaultStatus.costing_rejected,
  FaultStatus['for-approval'],
  FaultStatus.initially_reported,
  FaultStatus['pre-accepted'],
]

export function StatusDropdown({
  id,
  currentStatus,
}: {
  id: number
  currentStatus: FaultStatus
}) {
  const { updateStatus } = useStatusUpdate(id)
  const store = useCombobox()

  return (
    <Combobox
      store={store}
      onOptionSubmit={val => {
        updateStatus(val as FaultStatus)
        store.closeDropdown()
      }}
      width={'fit-content'}
    >
      <Combobox.Target targetType={'button'}>
        <UnstyledButton onClick={() => store.openDropdown()}>
          <StatusBadge status={currentStatus} />
        </UnstyledButton>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {SelectOptions.filter(el => el !== currentStatus).map(el => (
            <Combobox.Option value={el} key={el}>
              <StatusBadge status={el} />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
