import { FaultStatus } from '../../api/fault.types'
import { Badge, DefaultMantineColor } from '@mantine/core'

export const statusLabelMap: Record<FaultStatus, string> = {
  [FaultStatus.rejected]: 'Odrzucona',
  [FaultStatus.completed]: 'Usunięta',
  [FaultStatus.accepted]: 'Potwierdzona',
  [FaultStatus.in_progress]: 'W trakcie naprawy',
  [FaultStatus.reported]: 'Zgłoszona',
  [FaultStatus.costing_rejected]: 'Kosztorys odrzucony',
  [FaultStatus['for-approval']]: 'Do akceptacji',
  [FaultStatus.initially_reported]: 'Wstępnie zgłoszona',
  [FaultStatus['pre-accepted']]: 'Wstepnie zaakceptowana',
}

export const statusColorMap: Record<FaultStatus, DefaultMantineColor> = {
  [FaultStatus.rejected]: 'var(--mantine-color-gray-4)',
  [FaultStatus.completed]: 'var(--mantine-color-green-7)',
  [FaultStatus.accepted]: 'var(--mantine-color-red-7)',
  [FaultStatus.in_progress]: 'var(--mantine-color-blue-7)',
  [FaultStatus.reported]: 'var(--mantine-color-orange-4)',
  [FaultStatus.costing_rejected]: 'var(--mantine-color-blue-4)',
  [FaultStatus['for-approval']]: 'var(--mantine-color-blue-4)',
  [FaultStatus.initially_reported]: 'var(--mantine-color-blue-4)',
  [FaultStatus['pre-accepted']]: 'var(--mantine-color-orange-4)',
}

export const StatusBadge = ({
  status,
  active,
}: {
  status: FaultStatus
  active?: boolean
}) => {
  return <Badge color={statusColorMap[status]}>{statusLabelMap[status]}</Badge>
}
