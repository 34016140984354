import { Table, Button, LoadingOverlay, Badge } from '@mantine/core'
import { useFaults } from '../../api/useFaults'
import { formatDateToUI } from '../../utils/date.utils'

import styles from './FaultTable.module.scss'
import { Link } from 'react-router-dom'
import { StatusBadge } from '../StatusDropdown'

export const FaultTable = () => {
  const { data, isLoading } = useFaults()

  if (!data) return null

  const rows = data?.map(element => (
    <Table.Tr key={element.id}>
      <Table.Td>{element.id}</Table.Td>
      <Table.Td>{formatDateToUI(element.created)}</Table.Td>
      <Table.Td>
        <StatusBadge status={element.status} />
      </Table.Td>
      <Table.Td>{element.fault_type}</Table.Td>
      <Table.Td>{element.reporter.name}</Table.Td>
      <Table.Td>{element.reporter.email}</Table.Td>
      <Table.Td>
        <Link to={`/dashboard/details/${element.id}`}>
          <Button variant='white'>Details</Button>
        </Link>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <div className={styles.TableContainer}>
      <LoadingOverlay visible={isLoading} />
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Td>ID</Table.Td>
            <Table.Td>Date</Table.Td>
            <Table.Td>Status</Table.Td>
            <Table.Td>Type</Table.Td>
            <Table.Td>Reporter</Table.Td>
            <Table.Td>E-mail</Table.Td>
            <Table.Td />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  )
}
