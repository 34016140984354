import {
  DefaultOptions,
  QueryClient as ReactQueryConfig,
  QueryClientProvider,
} from 'react-query'

const queryDefaultOptions: DefaultOptions['queries'] = {
  staleTime: 60000,
  cacheTime: 300000,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
}

export const QueryClient = new ReactQueryConfig({
  defaultOptions: {
    queries: queryDefaultOptions,
  },
})

export function QueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={QueryClient}>{children}</QueryClientProvider>
  )
}
