import { useUpdateFlow } from '../../api/useUpdateFlow'
import { Button, Text } from '@mantine/core'
import { FaultStatus } from '../../api/fault.types'

export function EstimateForm({
  id,
  status,
}: {
  id: number
  status?: FaultStatus
}) {
  const { updateFlow } = useUpdateFlow(id)
  return (
    (status === FaultStatus.accepted ||
      status === FaultStatus.costing_rejected) && (
      <>
        <Text size='xs' mt={4}>
          Czy chcesz wysłać kosztorys do akceptacji?
        </Text>
        <Button
          mt='md'
          mr='md'
          variant='filled'
          color='teal'
          onClick={() => updateFlow({ flow: 4, decision: 'accepted' })}
        >
          Wyślij
        </Button>
      </>
    )
  )
}
