import { Modal, Image, ActionIcon, Center } from '@mantine/core'
import { useImageModal } from './ImageModal.context'
import { IconArrowBadgeRight, IconArrowBadgeLeft } from '@tabler/icons-react'

import styles from './ImageModal.module.scss'

export function ImageModal() {
  const { isOpen, closeModal, currentFile, prevFile, nextFile } =
    useImageModal()

  return (
    <Modal opened={isOpen} onClose={closeModal} size='90%'>
      <Modal.Body className={styles.ModalBody}>
        <Image src={currentFile?.file} fit='contain' className={styles.Image} />
        <Center className={styles.ButtonContainer}>
          <ActionIcon variant='default' size='xl' onClick={prevFile}>
            <IconArrowBadgeLeft />
          </ActionIcon>
          <ActionIcon variant='default' size='xl' onClick={nextFile}>
            <IconArrowBadgeRight />
          </ActionIcon>
        </Center>
      </Modal.Body>
    </Modal>
  )
}
